import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Membership from "./pages/Membership";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import NoPage from "./pages/NoPage";
import './styles/Style.css';

export default function App() {
  return (
      <BrowserRouter>
          <Routes>
              {/* Public-facing Routes */}
              <Route path="/" element={<Layout />}>

                  <Route index element={<Home />} />
                  <Route path="membership" element={<Membership />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="gallery" element={<Gallery />} />

                  {/* 404 */}
                  <Route path="*" element={<NoPage />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);