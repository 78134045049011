import Ribbon from '../components/Ribbon';
import Slider from '../components/Slider';
import { Link } from "react-router-dom";
import '../styles/Style.css';
import '../styles/Responsive.css';


import React from 'react';

function Home() {
    const { REACT_APP_PHOTOS_URL } = process.env;

    return (
        <>  
            <div className='container row space-between ptl pbl t-col'>
                <div className='half-width text-white'>
                    <h1 className='pbs'>HDT Owners <br /> Club of NSW</h1>
                    <p className='pbs mw540'>In 1980 Peter Brock established his HDT Special Vehicle Operations business to produce unique, 
                    world-class high performance vehicles based on the Australian built Holden car. Like the man himself, his vehicles now 
                    enjoy a legendary status.</p>
                    <Link to="/membership" className='btn-orange round-button'>
                        Learn More
                    </Link>
                </div>

                <div className='hero-graphic half-width t-ptm'>
                    <img className='full-width br15' src={`${REACT_APP_PHOTOS_URL}13.webp`} alt='hero image'/>
                </div>
            </div>

            <Ribbon />
            <div className="container">
                <div className="full-width text-white ptxl col align-center">
                    <h2 className='pbs text-center'>About HDT</h2>
                    <p className='text-center mw700'>HDT Owners Club of NSW Launched in 2003 The HDTOC incorporated was started by a team of passionate guys who saw a Niche for a HDT dedicated club. <br /> <br />
                    Founding President Brian Served 18 years at the helm with heavy support from then HDT SV owners Len & Sid Pennisi. With a family friendly atmosphere, the club grew from strength to strength throughout the mid 2000s and incorporating the New Generation (2007 to Present day) Vehicles built under the Peter Champion ownership of HDT SV.
                    <br /> <br />
                     We are proud that HDT Continues to support our club to this day.
                     <br /><br />
                     <span style={{fontWeight: 'bold'}}>Club meetings:</span> <br /> Meetings are held on the 1st Wednesday of the month (February to December) at <Link target='_blank' className='white-link' to={'https://www.bankstownsports.com/'}>Bankstown&nbsp;Sports&nbsp;Club</Link>
                     </p>
                </div>
            </div>
            <div className='slider-container container ptxl pbxl'>
                <Slider />
            </div>
        </>
    );
}

export default Home;