import React, { useState, useEffect } from 'react';
import MultiPageForm from '../components/MultiPageForm';

function MembershipForm() {
  const { REACT_APP_API_URL } = process.env;
  const formUrl = REACT_APP_API_URL + '/api/membership';
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    fetch(formUrl)
      .then((response) => response.json())
      .then((data) => {
        setFormData(data);
      })
      .catch((error) => {
        console.error(error);
      }); 
  }, [formUrl]);

  return (
    <div className='container col align-center ptl text-white'>
      <h1 className='text-center align-center pbl text-white'>Our Club</h1>
      <p className='text-center' style={{maxWidth: '600px'}}>The HDT Owners Club of NSW, launched in 2003, was created to add to the positive experience of owning a genuine HDT Special Vehicle. Our club welcomes all owners of genuine vehicles manufactured by HDT Special Vehicles Operations that either wear, or are eligible to wear, a HDT Build Plate.
    <br /><br />
        The culture of our club is informal, with emphasis on family participation & enthusiastic enjoyment of our HDT vehicles. Club activities are many & varied and include Car Shows, Show & Shines displays, Drive Days, Cruises and all types of Social activities. We aim to have at least one club activity per month.
      </p>  

      {formData && <MultiPageForm formData={formData} />}
    </div>
  );
}

export default MembershipForm;
