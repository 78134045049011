import React from "react";
import '../styles/Style.css';
import logo from "../images/hdt_logo.png";

import { BrowserRouter, Route, Link } from "react-router-dom";

function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <footer>
        <div className="footer-wrapper text-white">

        <div className="col full-height m-fullwidth">
          <h4 className="pbxs">Club Pages</h4>
            <div className="row align-center link-wrapper gap10">
              <div className="row gap10">
                  <Link target="_blank" to={'https://www.facebook.com/groups/337645423009539'}>NSW</Link>
                  <Link target="_blank" to={'https://www.facebook.com/p/HDT-Owners-Club-of-Victoria-Inc-100063743493459/?paipv=0&eav=Afb4_oRzKfjuJn0SOEXibdb3fF6tBOG0b_XEQ9qKxJe7O7yepKmMgBh7sxhXQsU68Cg&_rdr'}>Victoria</Link>
                  <Link target="_blank" to={'https://www.facebook.com/HDTOwnersClubofSAInc/'}>South Australia</Link>
              </div>
              <div className="row gap10">
                  <Link target="_blank" to={'http://www.hdtownersclubqld.com.au/#:~:text=The%20intent%20of%20the%20HDT,all%20HDT%20Special%20Vehicle%20owners'}>Queensland Owners Website</Link>
                  <Link target="_blank" to={'https://hdt.com.au/'}>HDT Official Website</Link>
                  <Link to={'https://hdt.com.au/showroom/'}>Fact Files</Link>
              </div>
            </div>
          </div>

          <div className="col space-between align-end m-fullwidth">
            <div className="main-logo">
              <img src={logo} alt="HDT Logo" />
            </div>
            <p className="ptxs">Copyright © HDT Owners Club NSW Inc {currentYear}</p>
          </div>
          
        </div>
    </footer>
  );
}

export default Footer;