import '../styles/Style.css';
import { Button } from 'react-bootstrap';
import { fontAwesome } from 'react-bootstrap';
import { BrowserRouter, Route, Link } from "react-router-dom";

import React from 'react';

function Ribbon() {
    return (
        <div className='ribcon full-width'>  
            <div className='container ptl pbl text-navy'>
                <h2 className='pbs'>
                    Member Activities
                </h2>
                <div>
                    <p className='pbxs mw700'>
                    The culture of our club is informal, with emphasis on family participation & enthusiastic 
                    enjoyment of our HDT vehicles. Club activities are many & varied and include:
                    </p>
                    <ul className='ribcon-list pbxs'>
                        <li>Car Shows</li>
                        <li>Show & Shines displays</li>
                        <li>Drive Days</li>
                        <li>Cruises and all types of Social activities</li>
                        <li>And more!</li>
                    </ul>
                    <p className='pbs mw700'>We aim to have at least one club activity per month.</p>
                    <Link to="/membership" className='btn-blue round-button'>
                        Apply Now
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Ribbon;