import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import '../styles/Slider.css';
import {useEffect, useState} from 'react';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: {
      items: 1,
      itemsFit: 'contain',
  }
}

  

export default function Slider() {

  const { REACT_APP_API_URL, REACT_APP_PHOTOS_URL } = process.env;
  const [images, setImages] = useState([]);
  const size = 3;
  const photoUrl = REACT_APP_PHOTOS_URL;


  useEffect(() => {
    fetch(`${REACT_APP_API_URL}/api/photos`)
      .then((response) => response.json())
      .then((json) => setImages(json))
  }, [])

  return (
    <AliceCarousel 
        mouseTracking
        infinite
        autoPlay
        autoPlayInterval={2500}
        animationType='fadeout'
        responsive={responsive}
        items={images.slice(0, size).map((image, index) => (
            <div key={index} style={{backgroundImage: `url(${photoUrl+image})`}} onDragStart={handleDragStart}role='presentation' className='carousel-img'></div>
        ))} />
  );
}
