import '../styles/Style.css';
import React from 'react';

function NoPage() {
    return (
        <>
            <h1>404: This page does not exist. Try checking the URL is entered correctly.</h1>
        </>
    );
}

export default NoPage;