import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!name || !email || !message) {
      toast.error('Please fill in all fields.');
      return;
    }

    // Handle form submission logic here
    //console.log('Form submitted:', { name, email, message });

    // Send form data to API
    const { REACT_APP_API_URL } = process.env;
    fetch(`${REACT_APP_API_URL}/api/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name, email, message}),
    }).then(r => {
        if (r.ok) {
            return r.json();
        } else {
            throw new Error('Failed to submit contact form');
        }
      })

    // Reset form fields
    setName('');
    setEmail('');
    setMessage('');

    // Display success notification
    toast.success('Contact form submitted successfully!');
  };

  return (

    <div id='contact' className='container col align-center ptl pbl text-white'>
        <h1 className='text-center pbs'>Contact Form</h1>
        <form className='col align-center' onSubmit={handleSubmit}>
            <div className='form-div pbs'>
                <h3 htmlFor="name">Name:</h3>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder='Please enter your name'
                />
            </div>
            <div className='form-div pbs'>
                <h3 htmlFor="email">Email:</h3>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder='Please enter your email'
                />
            </div>
            <div className='form-div pbm'>
                <h3 htmlFor="message">Message:</h3>
                <textarea 
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    placeholder='Please enter your message'
                />
            </div>
            <button className='btn-orange round-button' type="submit">Submit</button>
        </form>
      <ToastContainer />
    </div>
  );
};

export default Contact;
