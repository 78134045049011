import React, { useState } from "react";
import '../styles/Style.css';

import { BrowserRouter, Route, Link } from "react-router-dom";
import logo from "../images/hdt_logo.png";

function Navbar() {

  const [hamAnim, setHamAnim] = useState(false);

  const handleAnim = () => {
    if (!hamAnim) {
      setHamAnim(true);
    } else {
      setHamAnim(false);
    }
  }

  return (
      <nav className="stroke">
        <div className="nav-wrapper">
          <div className="main-logo">
            <Link to="/"> 
              <img src={logo} alt="HDT Logo" />
            </Link>
          </div>
          <div className={`navlink-wrapper ${hamAnim ? 'show-navlink' : ''}`}>
            <ul>
              <li>
                <Link onClick={handleAnim} to="/">Home</Link>
              </li>
              <li>
                <Link onClick={handleAnim} to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link onClick={handleAnim} to="/membership">Membership</Link>
              </li>
              <li>
                <Link onClick={handleAnim} to="/contact" className="round-button btn-orange">Contact</Link>
              </li>
            </ul>
          </div>
          <a className={`hamburger ${hamAnim ? 'hamAnim' : ''}`} onClick={handleAnim}>
            <div className={`ham-icon ${hamAnim ? 'invis' : ''}`}></div>
          </a>
        </div>
    </nav>
  );
}

export default Navbar;