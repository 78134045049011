import React, {useState} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const { REACT_APP_API_URL } = process.env;

const MultiPageForm = ({ formData }) => {

  const [formValues, setFormValues] = useState({});
  const [files, setFiles] = useState({
    hdtPapers: [],
    inspectionPapers: [],
    photosAndPlates: []
  });
  const [fileNames, setFileNames] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const currentPageData = formData.pages.find((page) => page.page === currentPage);

  const nextPage = () => {

    if (validateCurrentPage()) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  async function uploadFiles(memberId) {
    try {
      const formData = new FormData();
      formData.append('memberId', memberId);

      const { hdtPapers, inspectionPapers, photosAndPlates } = files;

      if (hdtPapers) {
        console.log('hdtPapers type:', typeof hdtPapers);
        hdtPapers.forEach(file => formData.append('hdtPapers', file));
      }
      if (inspectionPapers) {
        inspectionPapers.forEach(file => formData.append('inspectionPapers', file));
      }
      if (photosAndPlates) {
        photosAndPlates.forEach(file => formData.append('photosAndPlates', file));
      }

      const response = await fetch(`${REACT_APP_API_URL}/api/membership/upload-files`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        return await response.json(); // Return success status
      } else {
        throw new Error('Failed to upload files');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateCurrentPage() && agreementChecked) {
      const formDataWithFiles = { ...formValues, ...fileNames }; // Include file names in form data

      // Disable submit button
      document.getElementById("submit").disabled = true

      toast.info('Uploading files... Please don\'t close this window!');
      e.disable()

      fetch(`${REACT_APP_API_URL}/api/membership`, {
        method: 'POST',
        body: JSON.stringify(formDataWithFiles),
        headers: {
            'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then(data => {
            if (data.memberId) {
              uploadFiles(data.memberId).then(() => {
                // Perform the redirect here, e.g., using window.location
                toast.info('Membership Form Submitted Successfully!')
                setTimeout(() => {
                  window.location.href = '/';
                }, 3000);
              });
            }
          })
          .catch(() => {
            toast.error("Unable to submit form. Please try again later!")
          });

    } else if (!agreementChecked) {
      toast.error("You must agree to the terms before submitting.");
    }
  };

  const handleFileUpload = (e) => {
    const { id, files } = e.target;

    if (files) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [id]: Array.from(files), // Convert FileList to an array
      }));

      const fileNamesArray = Array.from(files).map((file) => file.name);
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [id]: fileNamesArray,
      }));
    }
  };

  const validateCurrentPage = () => {
    const requiredFields = currentPageData.fields.filter((field) => field.required);

    const missingRequiredFields = requiredFields.filter((field) => {
      if (field.type === 'file' && !formValues[field.id]) {
        toast.error(`Please upload ${field.label}`);
        return true;
      }
      return !formValues[field.id] || formValues[field.id].trim() === '';
    });

    if (missingRequiredFields.length > 0) {
      for (const field of missingRequiredFields) {
        if (field.id !== 'agreement') {
          toast.error(`${field.label} is a required field`);
        } else if (agreementChecked) {
          return true; // Return true if agreementChecked is true
        }
      }
      return false;
    }

    return true;
  };
  

  const handleInputChange = (e) => {
    const { id, type, checked } = e.target;
  
    // Special handling for checkbox inputs
    if (type === 'checkbox') {
      if (id === 'agreement') {
        setAgreementChecked(checked);
      } else {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [id]: type === 'checkbox' ? checked : e.target.value,
        }));
      }
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [id]: e.target.value,
      }));
    }
  };
  

  const getAssociatedFieldId = (checkboxId) => {
    const associatedField = currentPageData.fields.find((field) => field.associated_id === checkboxId);
    return associatedField ? associatedField.id : '';
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const { REACT_APP_RECAPTCHA_KEY } = process.env;

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  return (
    <div className='full-width col align-center'>
      <h2 className='pbs ptl'>{currentPageData.name}</h2>
      <form id='membershipForm' className='col align-center pbs text-white'>
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_KEY} />
        {currentPageData.fields.map((field) => (
          <div key={field.id} className='form-div pbs'>
            <label className='member-h3'>
              {field.label}
              {field.type === 'file' ? (
                <input
                  className='member-input'
                  type={field.type}
                  id={field.id}
                  multiple
                  onChange={handleFileUpload}
                  required={field.required}
                />
              ) : (
                <input
                  className='member-input'
                  type={field.type}
                  id={field.id}
                  value={formValues[field.id] || ''}
                  onChange={handleInputChange}
                  placeholder={field.placeholder}
                  required={field.required}
                  hidden={formValues[getAssociatedFieldId(field.id)] && !formValues[field.id]}
                />
              )}
            </label>
          </div>
        ))}
      </form>
      <div className='button-wrapper pbl'>
        {currentPage > 1 && (
          <button className='btn-orange round-button' type="button" onClick={previousPage}>
            Previous
          </button>
        )}
        {currentPage < formData.pages.length && (
          <button className='btn-orange round-button' type="button" onClick={nextPage}>
            Next
          </button>
        )}
        {currentPage === formData.pages.length && (
          <button
            className='btn-orange round-button'
            type="submit"
            id={"submit"}
            onClick={handleSubmit}
          >
            Submit
          </button>
        )}
      </div>
      <ToastContainer />
    </div>

  );
};

export default MultiPageForm;