import {useEffect, useState} from 'react';
import ModalImage from "react-modal-image";

const ImageGallery = () => {

  const { REACT_APP_API_URL, REACT_APP_PHOTOS_URL } = process.env;
  const [data, setData] = useState([]);

  //https://hdt-api.coralsoftware.dev/photos/xx.jpg

  useEffect(() => {
    fetch(`${REACT_APP_API_URL}/api/photos`)
      .then((response) => response.json())
      .then((json) => setData(json))
  }, [])

  return (
    <div className='container ptl pbl text-white'>
        <h1 className='pbm'>Image Gallery</h1>    
        <div className="gallery">
            {data.map((image) => (
            <div>
                <ModalImage className='gallery-img'
                     key={image}
                     small={REACT_APP_PHOTOS_URL + image}
                     large={REACT_APP_PHOTOS_URL + image}
                     alt="HDT Owners Club of NSW Gallery Image"
                     hideDownload={true}
                />
            </div>
            ))}
        </div>
    </div>
  );
};

export default ImageGallery;
